/* scroll dropdown animation  */
@-webkit-keyframes slide-down {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(100px); transform: translateY(100px); } }
  @keyframes slide-down { 0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(100px); transform: translateY(100px); }
}

.fixed {
  position: fixed;
  top: -100px;
  background-color: #273482;
  width: 100%;
  z-index: 3;
  padding-top:10px;
  -webkit-animation: slide-down .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-down .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

header > .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

header .logo > a > img {
  max-width: 238px;
}

header nav > #full-nav {
  height: 100%;
  font-size: 1em;
  font-weight: 600;
  list-style-type: none;
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  align-items: center;
}

header nav > #full-nav > li > a {
  padding: 0.29rem 0.75rem 0.43rem;
  transition: background-color .1s linear;
}

header nav > #full-nav > li > a:hover {
  padding: 1rem 0.75rem 1.42rem;
  margin-top: -1rem;
  background-color: rgba(0,0,0,.5);
}

header nav > #slider-menu {
  display: none;
}

/* Large Devices */
@media screen and (min-width: 1350px) {
  header nav {
    margin-top: 1rem;
  }

  header > .wrapper {
    padding: 2rem 0;
    max-width: 1350px;
    margin: auto;
  }

  div.fixed > header > div.wrapper {
    padding: 1rem 0;
  }

  
  .fixedHeaderPadding {
    height: 94.48px;
  }
}

/* Laptops */
@media screen and (max-width: 1350px) and (min-width: 992px) {
  header > .wrapper {
    padding: 1rem 2rem;
    width: 100%;
  }

  header nav {
    margin-top: 1rem;
  }

  header nav > #full-nav {
    font-size: 0.8em;
  }
  
  .fixedHeaderPadding {
    height: 66.48px;
  }
}

/* Tablets */
@media screen and (max-width: 992px) and (min-width: 576px) {
  header > .wrapper {
    padding: 1rem 2rem;
  }

  header .logo > a > img {  width: 20rem; }
  header nav > #full-nav { display: none; }

  header nav > #slider-menu {
    display: block;
    cursor: pointer;

    /* margin: 0.29rem 0.75rem 0.43rem; */
  }

  header nav > #slider-menu > button > img{
    width: 2rem;
  }

  header nav > #slider-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    background-color: #293480;
    height: 100vh;
    padding: 4rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
  }

  header nav > #slider-nav > ul {
    display: flex;
    flex-direction: column;
  }

  header nav > #slider-nav > ul > li {
    padding: 1rem;
  }

  header nav > #slider-nav > button {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  header nav > #slider-nav > button > img {
    width: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .fixedHeaderPadding {
    height: 66.48px;
  }
}

/* Phones */
@media screen and (max-width: 576px) {
  header > .wrapper {
    padding: 1rem 2rem;
  }
  
  header .logo > a > img {  width: 20rem; }
  header nav > #full-nav { display: none; }

  header nav > #slider-menu {
    display: block;
    cursor: pointer;

    /* margin: 0.29rem 0.75rem 0.43rem; */
  }

  header nav > #slider-menu > button > img{
    width: 2rem;
  }

  header nav > #slider-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background-color: #293480;
    height: 100vh;
    padding: 4rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  header nav > #slider-nav > ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  header nav > #slider-nav > ul > li {
    padding: 0.5rem;
  }

  header nav > #slider-nav > button {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  header nav > #slider-nav > button > img {
    width: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .fixedHeaderPadding {
    height: 61.48px;
  }
}
