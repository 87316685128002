/*-------------------*/
/* HOME STYLES */
/*-------------------*/
.header-container.fixed {
  padding: 0;
  z-index: 201;
}
header nav > #slider-nav {
  z-index: 200;
}
header nav > #slider-menu > button > img {
  padding-top: 0.4rem;
}
p {
  line-height: 1.8rem;
  font-weight: normal;
}
.hero {
  position: relative;
}
.hero .row {
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin: 0;
  position: relative;
}
.hero .box h1,
.hero .box p {
  max-width: 60%;
}
.hero .box h1 {
  margin-top: 0;
  font-size: 3rem;
}
.hero img {
  max-width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.page.home .hero .box ul,
ul.check-li {
  margin-top: 1rem;
  text-align: left;
}
.page.home .hero .box ul li,
ul.check-li li {
  font-size: 1.2rem;
  padding: 0.2rem 0;
  list-style-type: none;
}
.page.home .hero .box ul li::before,
ul.check-li li::before {
  content: "";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  background-image: url("/src/images/checkmark-icon.png");
  background-size: 1.3rem 1rem;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.login-signup-buttons {
  margin: 2.5rem 0 1rem;
}
.login-signup-buttons a {
  padding: 13px 38px;
  border: 2px solid #fff;
  border-radius: 30px;
  margin-right: 1.2rem;
  font-weight: bold;
}
.bg-layer {
  background: url("/src/images/confetti-banner-large.png");
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s linear;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 90% 100px;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  max-height: 600px;
  opacity: 0.8;
}
.bg-layer-left {
  background: url("/src/images/circle-peach.png");
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s linear;
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: 120%;
  background-position-y: 100%;
  width: 100%;
  height: 100%;
  top: -50%;
  z-index: -1;
}
.bg-layer-right {
  background: url("/src/images/circle-peach.png");
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s linear;
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: -26%;
  width: 100%;
  height: 100%;
  bottom: -32%;
  z-index: -2;
}
.explore-live-demos {
  background-color: #fff;
  color: #212529;
  padding: 1px 0 60px;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.get-started {
  background-color: #fff;
  color: #212529;
  padding: 1px 0 60px;
  position: relative;
  z-index: 8;
  overflow: hidden;
}
.simple-redemption-sites,
.need-more-features {
  padding: 30px 0 60px;
  position: relative;
  z-index: 8;
  overflow: hidden;
}
.explore-live-demos .row {
  margin: 0 auto 0.5rem;
}
.explore-live-demos .heading {
  margin-bottom: 0.5rem;
}
.explore-live-demos .heading h4 {
  font-family: "Merriweather", serif;
  letter-spacing: 1.2px;
  font-weight: bold;
  font-size: 19px;
  color: #3268b2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}
.white-section h2 {
  color: #386cbf;
  font-size: 3.5rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.demo-stores ul {
  text-align: center;
}
.demo-stores ul li {
  display: inline-block;
  width: calc(100% / 5);
  padding: 10px;
  text-align: center;
}
.demo-stores ul li h3 {
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;
}
.demo-stores ul li h3 {
  color: #24b0b0;
}
.demo-stores ul li a {
  background-color: #3268b2;
  border: none;
  padding: 13px 28px;
  display: inline-block;
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 14px;
}
.demo-stores ul li a:hover {
  transition: 0.3s !important;
  box-shadow: 2px 2px 5px 5px rgba(2, 2, 2, 0.1);
}
.demo-stores ul li a {
  background-color: #24b0b0;
}
.demo-stores ul li img {
  max-width: 100%;
  height: auto;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.03);
  display: block;
  margin: auto;
  width: 100%;
}
.get-started .wrapper .row > div,
.simple-redemption-sites .wrapper .row > div,
.explore-live-demos .wrapper .row > div,
.need-more-features .wrapper .row > div {
  max-width: 60%;
  margin: auto;
}
.white-section p a {
  color: #59caf2;
}
.white-section p a:hover {
  text-decoration: underline;
}
.getstarted-signup-btn {
  padding: 20px 75px !important;
  display: inline-block;
  margin-top: 3rem !important;
  transition: 0.3s !important;
}
.getstarted-signup-btn:hover {
  box-shadow: 2px 2px 5px 5px rgba(2, 2, 2, 0.1);
}
table.pricing th li {
  font-size: 15px;
}
/* Sitemap */
.section-sitemap h3 {
  text-align: left;
  color: #293480;
  text-transform: none;
  margin: 20px 0 5px 15px;
  line-height: 1.4em;
  font-size: 18px;
}
.section-sitemap ul {
  text-align: left;
  margin: 0;
  padding: 5px 0 0 15px;
}
.section-sitemap ul li {
  list-style-type: none;
  margin-bottom: 7px;
}
.section-sitemap ul li a {
  color: #000;
}

@media screen and (max-width: 2560px) {
  .bg-layer {
    background-position-x: 75%;
  }
}
@media screen and (max-width: 1920px) {
  .bg-layer {
    background-position-x: 90%;
  }
}
@media screen and (max-width: 1600px) {
  .bg-layer {
    background-position-x: 130%;
  }
}
@media screen and (max-width: 1400px) {
  .bg-layer {
    background-position-x: 200%;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1300px) {
  .bg-layer {
    background-position-x: 300%;
    max-height: 610px;
  }
  .hero .box h1,
  .hero .box p {
    max-width: 70%;
  }
}
@media screen and (max-width: 1280px) {
  .bg-layer {
    background-position-x: 280%;
  }
}
@media screen and (max-width: 1200px) {
  .hero .box h1 {
    font-size: 2.5rem;
  }
  .bg-layer {
    background-position-x: 200%;
    background-size: 80% 85%;
  }
  .bg-layer-left {
    background-position-x: 150%;
  }
  .bg-layer-right {
    background-position-x: -52%;
    bottom: -50%;
  }
}
@media screen and (max-width: 1100px) {
  .bg-layer {
    background-position-x: 135%;
    background-size: 75% 58%;
  }
  .hero img {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .demo-stores ul li {
    width: calc(100% / 3);
    margin-bottom: 2rem;
  }
  .bg-layer {
    background-position-x: 135%;
    background-size: 75% 65%;
  }
  .bg-layer-left {
    background-position-x: 170%;
    top: -25%;
  }
}
@media (max-width: 1024px) and (height: 1366px) {
  .bg-layer {
    background-size: 75% 36%;
  }
}
@media screen and (max-width: 992px) {
  .hero .box {
    text-align: center;
    margin-bottom: 3rem;
  }
  .hero .box h1,
  .hero .box p {
    max-width: 100%;
  }
  .bg-layer {
    background-position: 0 600px;
    bottom: 0%;
    background-size: contain;
    max-height: 100%;
  }
  .page.home .hero .box ul {
    max-width: 310px;
    margin: 2rem auto 0;
  }
  .login-signup-buttons {
    max-width: 310px;
    text-align: center;
    margin: 2rem auto 1rem;
  }
  .get-started .wrapper .row > div {
    max-width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .demo-stores ul li {
    width: calc(100% / 2);
  }
  .bg-layer-left {
    background-position-x: 220%;
  }
  .bg-layer-right {
    background-position-x: -120%;
  }
  .page.home .hero .box ul {
    margin: 2rem auto 0;
  }
}
@media screen and (max-width: 660px) {
  .bg-layer {
    background-position: 0 550px;
  }
  .bg-layer-left {
    background-size: 100%;
    right: -100%;
    background-position: 0 20%;
  }
  .bg-layer-right {
    background-size: 100%;
    left: -100%;
  }
}
@media screen and (max-width: 480px) {
  .bg-layer {
    background-position: 0 480px;
  }
  .demo-stores ul li {
    width: 100%;
  }
}
