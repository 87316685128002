footer {
  background-color: #0b1431;
  padding: 2rem 0;
  background-image: url("/src/images/footer-bg-img.png");
  background-repeat: no-repeat;
  background-position: calc(100% + 50px) top;
}

footer > .wrapper, footer > .row {
  margin: auto;
}

footer img {
  width: 60%;
  max-width: 238px;
}

footer ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

footer ul > li:not(:last-child)::after {
  content: "|";
  margin-left: 0.5rem;
  color: #75c3dd;
}

footer ul > li > a {
  color: #75c3dd;
  font-weight: 600;
  padding: 0 0.5rem;
}

footer ul > li > a:hover {
  color: #FFFFFF;
}

footer .copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .copyright a {
  padding: 0 0.2rem;
}

footer.homepage-footer-bottom {
  background: inherit
}

/* Large Devices */
@media screen and (min-width: 1200px) {

}

/* Laptops */
@media screen and (max-width: 1200px) and (min-width: 992px) {
  footer .copyright {
    display: inline;
  }
}

/* Tablets */
@media screen and (max-width: 992px) and (min-width: 576px) {
  footer .copyright {
    display: inline;
  }
  footer .wrapper img {
    margin-bottom: 1rem;
  }
}

/* Phones */
@media screen and (max-width: 576px) {
  footer .copyright {
    display: inline;
  }
}
