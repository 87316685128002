.sign-up-form {
  background-color: #FFFFFF;
  color: #000000;
  padding: 3rem;
  border-radius: 3rem;

  & > h3, & > p {
    text-align: center;
    margin: auto;
    padding: 0.5rem;
  }

  & > p {
    font-size: 1.5rem;

  }

  #stripe-exp, #stripe-cvv, #stripe-cc-number {
    @extend [type=text]
  }
}

.help-text {
  color: #363636;
  font-size: 0.75em;
}

.form-error {
  margin-top: 0.5rem;
  color: darkred;
}

.text-success {
  color: rgb(16, 105, 68);
}

.form-flash {
  padding: 10px 15px;
  border-radius: 0.5rem;
  margin: 0.25rem;
  line-height: 1.5rem;
  color: rgb(16, 105, 68);
  background-color: rgb(216, 246, 232);
}

.form-flash.error {
  color: rgb(194, 35, 43);
  background-color: rgb(251, 226, 224);
}

.input-group {
  display: flex;
  align-items: flex-start;
}

.input-group .input-group-button {
  margin-left: 0.5rem;
}

.input-group .form-control {
  flex: 1;
}

.white-section {
  background-color: #FFFFFF;
  color: #000000;
}

.white-section h1 {
  color: #386cbf;
  width: 80%;
}

.simple-store-1st-section-description {
  width: 60%;
  text-align: justify;
}

.simple-store-1st-section-unordered-list {
  columns: 2;
}

ul.simple-store-1st-section-unordered-list > li {
  list-style: none;
}

ul.simple-store-1st-section-unordered-list > li::before{
  content: '';
  display: inline-block;
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5rem;
  background-image: url('../icons/check.svg');;
}

.section-pricing-banner {
  background-color: #eaf0f7;
  color: #000000;
}

.section-pricing-banner h2 {
  color: #325d67;
}

.section-pricing-table {
  background-color: #FFF;
  color: #000;
}

.section-pricing-table table {
  margin: auto;
  border: #dee2e6 1px solid;
  width: 90%;
  max-width: 60em;
  border-collapse: collapse;
}

.section-pricing-table.solo  table {
  max-width: 30em;
}

.section-pricing-table table th {
  background-color: #f2f2f2;
  color: #000;
  padding: .9rem;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #dee2e6;
}

.section-pricing-table table td {
  border: 1px solid #dee2e6;
  padding: .75rem;
}

td > .header {
  padding: 20px 5px;
  font-weight: 300;
  font-size: 1.1em;
}

td > .header > .price {
  font-size: 1.8em;
  font-weight: 400;
}

.section-pricing-table table th,
.section-pricing-table table th ul,
.section-pricing-table table th p {
  font-weight: 300;
  margin: 1em 0;
  font-size: 1.1em;
}

.gray-bg {
  background-color: #f2f2f2;
}

.simple-store-pricing-top {
  display: none;
}


/* Large Devices */
@media screen and (min-width: 1200px) {
  .sign-up-form > h3 {
    width: 60%;
  }

  .sign-up-form > p {
    width: 80%;
  }

  .simple-store-1st-section-img {
    margin-top: -2.5rem;
  }

  .simple-store-add-on-section > div > article > div.row {
    padding: 0 8rem;
  }

  .section-pricing-banner {
    padding: 1rem 0 2.5rem;
  }
}

/* Laptops */
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .sign-up-form {
    padding: 3rem 2rem;
  }

  .white-section h1 {
    color: #386cbf;
    width: 100%;
  }

  .simple-store-1st-section-description {
    width: 100%;
    text-align: justify;
  }

  .simple-store-1st-section-img {
    width: 100%;
  }

  .white-section > div > article:nth-child(2) {
    padding-top: 10%;
  }

  .simple-store-add-on-section > div > article > div.row {
    padding: 0 3rem;
  }

  .section-pricing-banner {
    padding: 1rem 0 2.5rem;
  }
}

/* Tablets */
@media screen and (max-width: 992px) and (min-width: 576px) {
  .sign-up-form {
    padding: 3rem 2rem;
  }

  .sign-up-form > p {
    font-size: 1.25rem;
  }

  .white-section h1 {
    color: #386cbf;
    width: 100%;
  }

  .simple-store-1st-section-description {
    width: 100%;
    text-align: center;
  }

  .simple-store-1st-section-img {
    margin-top: 3rem;
  }

  ul.simple-store-1st-section-unordered-list > li {
    text-align: left;
  }

  .section-pricing-banner {
    padding: 1rem 0;
  }
}

/* Phones */
@media screen and (max-width: 576px) {
  .sign-up-form {
    padding: 3rem 1.5rem;
  }

  .sign-up-form > p {
    font-size: 1.25rem;
  }

  .white-section h1 {
    color: #386cbf;
    width: 100%;
  }

  .simple-store-1st-section-description {
    width: 100%;
    text-align: center;
  }

  .simple-store-1st-section-img {
    margin-top: 3rem;
  }

  ul.simple-store-1st-section-unordered-list > li {
    text-align: left;
  }

  .section-pricing-banner {
    padding: 1rem;
  }

  .simple-store-pricing-top {
    display: table-cell;
    column-span: 2;
  }
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: #59caf2;
  background: -moz-linear-gradient(left, #59caf2 10%, rgba(0,0,0, 0) 42%);
  background: -webkit-linear-gradient(left, #59caf2 10%, rgba(0,0,0, 0) 42%);
  background: -o-linear-gradient(left, #59caf2 10%, rgba(0,0,0, 0) 42%);
  background: -ms-linear-gradient(left, #59caf2 10%, rgba(0,0,0, 0) 42%);
  background: linear-gradient(to right, #59caf2 10%, rgba(0,0,0, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #59caf2;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #FFFFFF;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
