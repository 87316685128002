/*-------------------*/
/* IMPORTS */
/*-------------------*/
@import url('https://fonts.googleapis.com/css?family=Josefin+Slab|Lato:300,400,700|Merriweather:400,700|Open+Sans:300,400,700|PT+Sans:400,700&display=swap');

@import "reset.css";
@import "normalize.css";
@import "header.css";
@import "footer.css";
@import "content.scss";
@import "home.css";

/*-------------------*/
/* Global */
/*-------------------*/

html {
  color: #FFFFFF;
  background-color: #273482;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6, .heading {
  font-family: 'PT Sans', sans-serif;
  margin: 1.5rem 0;
}


h1 { font-size: 4em; }
h2 { font-size: 2em; }
h3 { font-size: 1.5em; }
p { font-size: 1.3em; }

.heading {
  font-weight: 600;
  letter-spacing: 2px;
}

strong {
  font-weight: 600;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

[type=text], [type=email], [type=tel], [type=password] {
  padding: 11px;
  font-size: 14px;
  border-width: 1px;
  border-color: #cfcfcf;
  background-color: #FFFFFF;
  color: #000000;
  border-style: solid;
  width: 100%;
  border-radius: 8px;
  box-shadow: 1px 0px 3px rgba(239,239,239,.97);
}

[type=text]:focus, [type=email]:focus, [type=tel]:focus, [type=password]:focus {
  outline: none;
}

[type="checkbox"], [type="radio"] {
  margin-right: 0.5rem;
}

.wrapper {
  width: 1350px;
  margin: auto;
}

.spacer {
  min-height: 3rem;
}

.spacer-medium {
  min-height: 10rem;
}

.spacer-large {
  min-height: 20rem;
}

#overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.4);
}

.btn-small {
  font-size: 1.15rem;
  padding: 0.75rem 1.25rem;
  line-height: 1.25rem;
  border: #FFFFFF 1px solid;
  border-radius: 5rem;
  background-color: #59caf2;
  color: #FFFFFF;
}

.btn-medium {
  text-transform: uppercase;
  font-size: 1.15rem;
  padding: 1rem 3rem;
  border: #FFFFFF 3px solid;
  border-radius: 2rem;
  line-height: 4rem;
  transition: background-color .1s linear;
}

.btn-medium:hover {
  background-color: rgba(0,0,0,.5);
  box-shadow: 2px 2px 5px 5px rgba(2,2,2,.1);
}

.btn-large {
  text-transform: uppercase;
  font-size: 1.15rem;
  padding: 1.5rem 8rem;
  margin: auto;
  border: #FFFFFF 0 solid;
  border-radius: 3rem;
  transition: background-color .1s linear;
  color: #FFFFFF;
  font-weight: 600;
  background-color: #59caf2;
  cursor: pointer;
}

.btn-large:hover {
  box-shadow: 2px 2px 5px 5px rgba(2,2,2,.1);
}

.btn-large:disabled, .btn-medium:disabled, .btn-small:disabled {
  background-color: #b1d8ec;
  color: #ffffff;
  cursor: initial;
}

.btn-large:disabled:hover, .btn-medium:disabled:hover, .btn-small:disabled:hover {
  box-shadow: none;
}

.ul-simple {
  list-style-type: disc;
  text-align: left;
  font-size: 1.2em;
  margin: 1.5rem 0;
  margin-left: 1.2rem;
}

.ul-simple li {
  line-height: 1.5em;
}

div.hidden {
  display: none;
}

/*-------------------*/
/* Grid System */
/*-------------------*/
.row {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  flex: 0 1 auto;
  margin: 1.5rem auto;
}

.row-no-margin {
  margin: 0 auto;
}

.row > .col-lg-one,
.row > .col-lg-two,
.row > .col-lg-three,
.row > .col-lg-four,
.row > .col-lg-five,
.row > .col-lg-six,
.row > .col-lg-seven,
.row > .col-lg-eight,
.row > .col-lg-nine,
.row > .col-lg-ten,
.row > .col-lg-eleven,
.row > .col-lg-twelve,
.row > .col-md-one,
.row > .col-md-two,
.row > .col-md-three,
.row > .col-md-four,
.row > .col-md-five,
.row > .col-md-six,
.row > .col-md-seven,
.row > .col-md-eight,
.row > .col-md-nine,
.row > .col-md-ten,
.row > .col-md-eleven,
.row > .col-md-twelve,
.row > .col-sm-one,
.row > .col-sm-two,
.row > .col-sm-three,
.row > .col-sm-four,
.row > .col-sm-five,
.row > .col-sm-six,
.row > .col-sm-seven,
.row > .col-sm-eight,
.row > .col-sm-nine,
.row > .col-sm-ten,
.row > .col-sm-eleven,
.row > .col-sm-twelve {
  padding: 0 0.3rem;
  margin: 0.3rem 0;
  box-sizing: border-box;
}

/* Laptops and other large screens */
.row > .col-lg-one { width: 8.33%; }
.row > .col-lg-two { width: 16.667%; }
.row > .col-lg-three { width: 25%; }
.row > .col-lg-four { width: 33.333%; }
.row > .col-lg-five { width: 41.667%; }
.row > .col-lg-six { width: 50%; }
.row > .col-lg-seven { width: 58.333%; }
.row > .col-lg-eight { width: 66.667%; }
.row > .col-lg-nine { width: 75%; }
.row > .col-lg-ten { width: 83.333%; }
.row > .col-lg-eleven { width: 91.667%; }
.row > .col-lg-twelve { width: 100%; }


/*-------------------*/
/* Media Queries */
/*-------------------*/


/* Large Devices */
@media screen and (min-width: 1350px) {
  /*-------------------*/
  /* Global */
  /*-------------------*/
  .hide-xl { display: none; }
  .align-left-xl { text-align: left; }


  /*-------------------*/
  /* Grid System */
  /*-------------------*/
}

/* Laptops */
@media screen and (max-width: 1350px) and (min-width: 992px) {
  /*-------------------*/
  /* Global */
  /*-------------------*/
  .hide-lg { display: none; }
  .align-center-lg { text-align: center; }
  .align-left-lg { text-align: left; }

  .wrapper {
    padding: 2rem;
    width: 100%;
  }

  .spacer {
    min-height: 2rem;
  }

  .spacer-medium {
    min-height: 7rem;
  }

  .spacer-large {
    min-height: 15rem;
  }


  /*-------------------*/
  /* Grid System */
  /*-------------------*/
  .row {
    margin: 0 auto;
  }
}

/* Tablets */
@media screen and (max-width: 992px) and (min-width: 576px) {
  /*-------------------*/
  /* Global */
  /*-------------------*/
  .hide-md { display: none; }
  .align-center-md { text-align: center; }
  .align-left-md { text-align: left; }

  .wrapper {
    padding: 2rem;
    width: 100%;
  }

  .spacer {
    min-height: 2rem;
  }

  .spacer-medium {
    min-height: 2rem;
  }

  .spacer-large {
    min-height: 7rem;
  }

  h1 { font-size: 3em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }


  /*-------------------*/
  /* Grid System */
  /*-------------------*/
  .row {
    margin: 0 auto;
  }

  .row > .col-md-one { width: 8.33%; }
  .row > .col-md-two { width: 16.667%; }
  .row > .col-md-three { width: 25%; }
  .row > .col-md-four { width: 33.333%; }
  .row > .col-md-five { width: 41.667%; }
  .row > .col-md-six { width: 50%; }
  .row > .col-md-seven { width: 58.333%; }
  .row > .col-md-eight { width: 66.667%; }
  .row > .col-md-nine { width: 75%; }
  .row > .col-md-ten { width: 83.333%; }
  .row > .col-md-eleven { width: 91.667%; }
  .row > .col-md-twelve { width: 100%; }
}

/* Phones */
@media screen and (max-width: 576px) {
  /*-------------------*/
  /* Global */
  /*-------------------*/
  .hide-sm { display: none; }
  .align-center-sm { text-align: center; }
  .align-left-sm { text-align: left; }

  html {
    font-size: 12px;
  }

  .wrapper {
    padding: 1rem;
    width: 100%;
  }

  .spacer {
    min-height: 2rem;
  }

  .spacer-medium {
    min-height: 2rem;
  }

  .spacer-large {
    min-height: 2rem;
  }

  h1 { font-size: 2em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }


  /*-------------------*/
  /* Grid System */
  /*-------------------*/
  .row {
    margin: 0 auto;
  }
  
  .row > .col-md-one { width: 8.33%; }
  .row > .col-md-two { width: 16.667%; }
  .row > .col-md-three { width: 25%; }
  .row > .col-md-four { width: 33.333%; }
  .row > .col-md-five { width: 41.667%; }
  .row > .col-md-six { width: 50%; }
  .row > .col-md-seven { width: 58.333%; }
  .row > .col-md-eight { width: 66.667%; }
  .row > .col-md-nine { width: 75%; }
  .row > .col-md-ten { width: 83.333%; }
  .row > .col-md-eleven { width: 91.667%; }
  .row > .col-md-twelve { width: 100%; }

  .row > .col-sm-one { width: 8.33%; }
  .row > .col-sm-two { width: 16.667%; }
  .row > .col-sm-three { width: 25%; }
  .row > .col-sm-four { width: 33.333%; }
  .row > .col-sm-five { width: 41.667%; }
  .row > .col-sm-six { width: 50%; }
  .row > .col-sm-seven { width: 58.333%; }
  .row > .col-sm-eight { width: 66.667%; }
  .row > .col-sm-nine { width: 75%; }
  .row > .col-sm-ten { width: 83.333%; }
  .row > .col-sm-eleven { width: 91.667%; }
  .row > .col-sm-twelve { width: 100%; }
}

/*-------------------*/
/* Utils */
/*-------------------*/

/* Display */
.block { display: block; }

/* Align */
.align-right { text-align: right; }
.align-left { text-align: left; }
.align-center { text-align: center; }

/* Text */
.text-uppercased { text-transform: uppercase; }
.text-bold { font-weight: 700; }
.text-underlined { text-decoration: underline; }

/* Style */
.color-secondary { color: #6ebdf5; }
.color-tertiary { color: #59caf2; }
.color-danger { color: darkred; }
.font-secondary { font-family: 'Merriweather', serif; }

/* Margin */
.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 0.25rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-3 { margin-left: 0.75rem; }
.ml-4 { margin-left: 1rem; }
.ml-5 { margin-left: 1.25rem; }
.ml-6 { margin-left: 1.5rem; }
.ml-7 { margin-left: 1.75rem; }
.ml-8 { margin-left: 2rem; }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: 0.25rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-3 { margin-right: 0.75rem; }
.mr-4 { margin-right: 1rem; }
.mr-5 { margin-right: 1.25rem; }
.mr-6 { margin-right: 1.5rem; }
.mr-7 { margin-right: 1.75rem; }
.mr-8 { margin-right: 2rem; }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 0.75rem; }
.mt-4 { margin-top: 1rem; }
.mt-5 { margin-top: 1.25rem; }
.mt-6 { margin-top: 1.5rem; }
.mt-7 { margin-top: 1.75rem; }
.mt-8 { margin-top: 2rem; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-5 { margin-bottom: 1.25rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mb-7 { margin-bottom: 1.75rem; }
.mb-8 { margin-bottom: 2rem; }

/*-------------------*/
/* AlpineJS */
/*-------------------*/
[x-cloak] {
  display: none !important;
}
